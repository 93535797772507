/* ================================= */
/*            Event Based            */
/* ================================= */

import {
  CodeExampleSDK,
  CodeExampleUsecases,
} from "@/feature/components/FeatureCodeExample";

type CodeExamplesMap = Record<
  CodeExampleSDK,
  Record<CodeExampleUsecases, string>
>;

/**
 * React
 */

const reactCommon = (publishableKey: string) => /*jsx*/ `
import { BucketProvider, useFeature } from "@bucketco/react-sdk";

// Initialize SDK as a React context provider.
const App = () => (
  <BucketProvider
    publishableKey="${publishableKey}"
    user={{ id: "john_doe", name: "John Doe" }}
    company={{ id: "acme_inc", name: "Acme Inc." }}
  >
    <MyFeature />
  </BucketProvider>
);
`;

const reactAccess = (flagKey: string) => /*jsx*/ `
const MyFeature = () => {
  const { isEnabled } = useFeature("${flagKey}");

  // Check if the feature is enabled for user.
  return isEnabled ? "You have access!" : null;
};
`;

const reactAdoption = (flagKey: string) => /*jsx*/ `
const MyFeature = () => {
  const { track } = useFeature("${flagKey}");

  // Track feature adoption for the user.
  return <button onClick={() => track()}>Feature used!</button>;
};
`;

const reactFeedback = (flagKey: string) => /*jsx*/ `
const MyFeature = () => {
  const { requestFeedback } = useFeature("${flagKey}");

  // Ask a customer for feedback.
  return (
    <button
      onClick={(e) =>
        requestFeedback({
          title: "Do you like this feature?",
          position: {
            type: "POPOVER",
            anchor: e.currentTarget as HTMLElement,
          },
        })
      }
    >
      Give feedback
    </button>
  );
};
`;

const reactConfig = (flagKey: string) => /*jsx*/ `
const MyComponent = () => {
  const { config: { key, payload } } = useFeature("${flagKey}");

  // Print the configuration and payload.
  return (
    <div>
      <h3>Configuration: {key}</h3>
      <pre>
        {JSON.stringify(payload)}
      </pre>
    </div>
  );
};
`;

/**
 * Browser
 */

const browserCommon = (publishableKey: string) => /*js*/ `
import { BucketClient } from "@bucketco/browser-sdk";

// Setup and configure the SDK.
const bucketClient = new BucketClient({
  publishableKey: "${publishableKey}",
  user: { id: "john_doe", name: "John Doe" },
  company: { id: "acme_inc", name: "Acme Inc." }
});
await bucketClient.initialize();
`;

const browserAccess = (flagKey: string) => /*js*/ `
const { isEnabled } = bucketClient.getFeature("${flagKey}");

// Check if the feature is enabled for the user.
if (isEnabled) {
  console.log("You have access!");
}
`;

const browserAdoption = (flagKey: string) => /*js*/ `
const { track } = bucketClient.getFeature("${flagKey}");
const button = document.querySelector("button");
button.addEventListener("click", (e) => {
  // Track feature adoption for the user.
  track();
});
`;

const browserFeedback = (flagKey: string) => /*js*/ `
const { requestFeedback } = bucketClient.getFeature("${flagKey}");
const button = document.querySelector("button");
button.addEventListener("click", (e) => {
  // Ask a customer for feedback.
  requestFeedback({
    title: "Do you like this feature?",
    position: {
      type: "POPOVER",
      anchor: e.currentTarget as HTMLElement,
    },
  });
});
`;

const browserConfig = (flagKey: string) => /*js*/ `
const { config: { key, payload } } = bucketClient.getFeature("${flagKey}");

// Print the configuration and payload.
console.log(\`Using configuration: \${key}, with payload: \${payload}\`);
`;

/**
 * Node
 */

const nodeCommon = (secretKey: string) => /*js*/ `
import { BucketClient } from "@bucketco/node-sdk";

// Setup and configure the SDK.
const bucketClient = new BucketClient({
  secretKey: "${secretKey}",
});
await bucketClient.initialize();

// Create a bound instance for a specific user and company.
const boundClient = bucketClient.bindClient({
  user: { id: "john_doe", name: "John Doe" },
  company: { id: "acme_inc", name: "Acme Inc." },
});
`;

const nodeAccess = (flagKey: string) => /*js*/ `
const { isEnabled } = boundClient.getFeature("${flagKey}");

// Check if the feature is enabled for the user.
if (isEnabled) {
  console.log("User has access!");
}
`;

const nodeAdoption = (flagKey: string) => /*js*/ `
const { track } = boundClient.getFeature("${flagKey}");

// Track feature adoption for the user.
track();
`;

const nodeFeedback = (_flagKey: string) => /*js*/ `
/**
 * Currently not available in the Node.js SDK.
 */
`;

const nodeConfig = (flagKey: string) => /*js*/ `
const { config: { key, payload } } = boundClient.getFeature("${flagKey}");

// Print the configuration and payload.
console.log(\`Using configuration: \${key}, with payload: \${payload}\`);
`;

/**
 * Export
 */

export const codeExamplesByUsecase = (
  flagKey: string,
  publishableKey: string,
  secretKey: string,
): CodeExamplesMap => ({
  react: {
    access: reactCommon(publishableKey) + reactAccess(flagKey),
    adoption: reactCommon(publishableKey) + reactAdoption(flagKey),
    feedback: reactCommon(publishableKey) + reactFeedback(flagKey),
    config: reactCommon(publishableKey) + reactConfig(flagKey),
  },
  node: {
    access: nodeCommon(secretKey) + nodeAccess(flagKey),
    adoption: nodeCommon(secretKey) + nodeAdoption(flagKey),
    feedback: nodeFeedback(flagKey),
    config: nodeCommon(secretKey) + nodeConfig(flagKey),
  },
  browser: {
    access: browserCommon(publishableKey) + browserAccess(flagKey),
    adoption: browserCommon(publishableKey) + browserAdoption(flagKey),
    feedback: browserCommon(publishableKey) + browserFeedback(flagKey),
    config: browserCommon(publishableKey) + browserConfig(flagKey),
  },
});
